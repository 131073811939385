import React, {useEffect, useState} from "react";
import {ACCOUNT} from "../../posConstants";
//css
import Classes from '../../../../styles'

import {Box, InputLabel, Tooltip, Typography} from "@material-ui/core/";
import {withRouter} from "react-router-dom";
import {Checkbox, Grid, Hidden, Input} from "@material-ui/core";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {activePaySecondCurrency, setPaymentCurrencyValues, toggleRrhhPayment} from "../../redux/asyncActions";
import {useDispatch} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {rrhh_payment} from "../../../rumi-settings/permissionsConstants";

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        fontSize: '1em',
        fontWeight: "410",
    }
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const AmountDetail = ({
                          location,
                          cart,
                          consumptions,
                          exchangeRate,
                          paymentValues,
                          paymentType,
                          selectedClient,
                          activedPaySecondCurrency,
                          paymentTypeSelected,
                          isRrhhPayment,
                          permissionsList
                      }) => {
    const classes = Classes();
    const tooltipStyles = useStylesBootstrap();
    const dispatch = useDispatch();
    const [openTooltip, setOpenTooltip] = React.useState(false);
    const rrhhPaymentPermission = permissionsList.includes(rrhh_payment);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [details, setDetails] = useState({});
    const [initInflowPrimary, setInitInflowPrimary] = useState(false);
    const open = Boolean(anchorEl);
    const inactiveSecondCurrency = exchangeRate.exchangeRate === null

    const countryCode = localStorage.getItem('country')
    let totalSecondaryCurrency = (Number(details.total) / parseFloat(exchangeRate.exchangeRate))

    if(countryCode === 'CL'){
        totalSecondaryCurrency = (Number(details.total) * parseFloat(exchangeRate.exchangeRate))
    }
    const valueExchangeRate = Number(exchangeRate.exchangeRate).toFixed(3)
    const validatePaymentType = selectedClient !== "" && parseInt(details.total) > 0
    const [secondaryInputProps, setSecondaryInputProps] = useState({min: 0, step: 0.01})
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const secondaryCurrencyChange = (event) => {
        const newSecondaryValue = Number(event.target.value);
        const countryCode = localStorage.getItem('country')
        let exchange = parseFloat(exchangeRate.exchangeRate);
        if(countryCode === 'CL'){
            exchange = parseFloat(exchangeRate.exchangeRate);
        }
        const totalToPay = details.total ? parseFloat(details.total) : 0;
        let newPrimaryValue = 0
        if (newSecondaryValue >= 0) {
            dispatch(setPaymentCurrencyValues(newPrimaryValue, newSecondaryValue, exchange, totalToPay));
        }
        setInitInflowPrimary(false)
    };
    const currentPath = location.pathname;
    const isStoreRoute = currentPath.includes("store");

    useEffect(() => {
        const defaultValues = {total: 0, subTotal: 0, taxes: 0, charges: 0};

        if (currentPath.includes("consumptions")) {
            setDetails(consumptions);
        } else if (isStoreRoute) {
            setDetails(cart);
        } else {
            if (cart) {
                setDetails(cart);
            } else {
                setDetails(defaultValues);
            }
        }

        if (!validatePaymentType) {
            setInitInflowPrimary(true)
        }
        if (paymentType === 'CARD') {
            setSecondaryInputProps({min: 0, step: 0.01, max: totalSecondaryCurrency})
        } else {
            setSecondaryInputProps({min: 0, step: 0.01,})
        }
    }, [location, cart, consumptions, paymentType,activedPaySecondCurrency]);


    const handleTooltipClose = () => {
        setOpenTooltip(false);
    };
    const handleTooltipOpen = () => {
        setOpenTooltip(true);
    };

    return (
        <React.Fragment>
            {
                !inactiveSecondCurrency && validatePaymentType &&
                <Grid item xs={12}>
                    <Box display="inline"
                         style={!paymentTypeSelected || paymentTypeSelected === ACCOUNT ? {cursor: 'not-allowed'} : {}}>
                        <Checkbox style={paymentTypeSelected && paymentTypeSelected !== ACCOUNT ? {
                            padding: 0,
                            marginRight: 10,
                            color: "#5BE576"
                        } : {padding: 0, marginRight: 10}}
                                  checked={activedPaySecondCurrency}
                                  disabled={!paymentTypeSelected || paymentTypeSelected === ACCOUNT}
                                  onChange={(e) => dispatch(activePaySecondCurrency(e.target.checked))}
                                  inputProps={{'aria-label': 'primary checkbox'}}
                        />
                    </Box>
                    <Typography variant="caption" color="textSecondary">
                        Pay in {exchangeRate.secondary.code}
                    </Typography>
                    <Box display="inline"
                         style={!rrhhPaymentPermission || !paymentTypeSelected || paymentTypeSelected === ACCOUNT ? {cursor: 'not-allowed'} : {}}>
                        <Checkbox
                            style={rrhhPaymentPermission && paymentTypeSelected && paymentTypeSelected !== ACCOUNT ? {
                                padding: 0,
                                marginRight: 10,
                                color: "#5BE576"
                            } : {padding: 0, marginRight: 10}}
                            checked={isRrhhPayment}
                            disabled={!rrhhPaymentPermission || !paymentTypeSelected || paymentTypeSelected === ACCOUNT}
                            onChange={(e) => dispatch(toggleRrhhPayment(e.target.checked))}
                            inputProps={{'aria-label': 'primary checkbox'}}
                        />
                    </Box>
                    <Typography variant="caption" color="textSecondary">
                        RRHH Payment
                    </Typography>
                    {
                        activedPaySecondCurrency &&
                        <Box ml={1.5} mt={1} display="flex" flexDirection="row" alignItems="center"
                             justifyContent="space-between">
                            <Box width="100%" mr={1.5}>
                                <InputLabel style={{fontSize: 12, color: "#5BE576"}}
                                            htmlFor="input-with-icon-adornment">Inflow {exchangeRate.secondary.symbol}</InputLabel>
                                <Input type="number" name="secondaryCurrencyValue"
                                       id="secondaryCurrencyValue"
                                       value={paymentValues.secondaryValue}
                                       inputProps={{...secondaryInputProps}}
                                       disabled={!details.total > 0}
                                       className={`general-forms- ${classes.forms.textField}`}
                                       onChange={event => secondaryCurrencyChange(event)}
                                />
                            </Box>
                            <Box width="100%">
                                {paymentValues.excessPayment ?
                                    <InputLabel style={{fontSize: 12, color: "#EB5588"}}
                                                htmlFor="input-with-icon-adornment">Outflow {exchangeRate.primary.symbol}</InputLabel>
                                    :
                                    <InputLabel style={{fontSize: 12, color: "#39CDC8"}}
                                                htmlFor="input-with-icon-adornment">Inflow {exchangeRate.primary.symbol}</InputLabel>

                                }
                                {
                                    paymentValues.devolution === 0 ?
                                        <Input type="text" name="devolutionValue"
                                               id="devolutionValue"
                                               value={parseFloat(paymentValues.primaryValue).toFixed(2)}
                                               disabled
                                               className={`general-forms- ${classes.forms.textField}`}
                                        />
                                        :
                                        <Input type="text" name="devolutionValue"
                                               id="devolutionValue"
                                               value={initInflowPrimary ? details.total : parseFloat(paymentValues.devolution).toFixed(2)}
                                               disabled
                                               className={`general-forms- ${classes.forms.textField}`}
                                        />

                                }
                            </Box>
                        </Box>
                    }
                </Grid>
            }
            <Grid item xs={12}>
                <Grid container item>
                    <Grid item xs={12}>
                        <Typography variant="caption" color="textSecondary">
                            Total Amount
                        </Typography>
                    </Grid>
                    <Grid item xs={1}/>
                    <Grid item xs={10}>
                        <Hidden mdUp>
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                                <Tooltip arrow classes={tooltipStyles}
                                         PopperProps={{
                                             disablePortal: true,
                                         }}
                                         onClose={handleTooltipClose}
                                         open={openTooltip}
                                         disableFocusListener
                                         disableHoverListener
                                         disableTouchListener
                                         title={<Box style={{padding: '1em', width: '14em'}}>
                                             {
                                                 !inactiveSecondCurrency &&
                                                 <Box display="flex">
                                                     <Box flexGrow={1} color="text.secondary">Exchange rate:</Box>
                                                     <Box style={{color: 'white'}}>{valueExchangeRate}</Box>
                                                 </Box>
                                             }
                                             <Box display="flex">
                                                 <Box flexGrow={1} color="text.secondary">Subtotal:</Box>
                                                 <Box style={{color: 'white'}}>{details.subTotal}</Box>
                                             </Box>
                                             <Box display="flex">
                                                 <Box flexGrow={1} color="text.secondary">Taxes:</Box>
                                                 <Box style={{color: 'white'}}>{details.taxes}</Box>
                                             </Box>
                                             <Box display="flex">
                                                 <Box flexGrow={1} color="text.secondary">Fees/charges:</Box>
                                                 <Box style={{color: 'white'}}>{details.charges}</Box>
                                             </Box>
                                         </Box>}
                                         placement="top">
                                    <Box display="flex" mt={1} alignItems="center" justifyContent="center"
                                         flexDirection="column"
                                         style={{cursor: 'pointer'}} onClick={handleTooltipOpen}>
                                        <Typography variant="h6" color="textPrimary"
                                                    className={` ${classes.typography.fontBold}`}
                                                    style={{lineHeight: 1}}>
                                            {exchangeRate.primary.code} {details.total}
                                        </Typography>
                                        {
                                            (!inactiveSecondCurrency && activedPaySecondCurrency) &&
                                            <Typography variant="body1" color="textSecondary"
                                                        className={` ${classes.typography.fontBold}`}>
                                                {exchangeRate.secondary.code} {totalSecondaryCurrency.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            </Typography>
                                        }
                                    </Box>
                                </Tooltip>
                            </ClickAwayListener>
                        </Hidden>
                        <Hidden smDown>
                            <BootstrapTooltip
                                title={<Box style={{padding: '1em', width: '14em'}}>
                                    {
                                        !inactiveSecondCurrency &&
                                        <Box display="flex">
                                            <Box flexGrow={1} color="text.secondary">Exchange rate:</Box>
                                            <Box style={{color: 'white'}}>{valueExchangeRate}</Box>
                                        </Box>
                                    }
                                    <Box display="flex">
                                        <Box flexGrow={1} color="text.secondary">Subtotal:</Box>
                                        <Box style={{color: 'white'}}>{details.subTotal}</Box>
                                    </Box>
                                    <Box display="flex">
                                        <Box flexGrow={1} color="text.secondary">Taxes:</Box>
                                        <Box style={{color: 'white'}}>{details.taxes}</Box>
                                    </Box>
                                    <Box display="flex">
                                        <Box flexGrow={1} color="text.secondary">Fees/charges:</Box>
                                        <Box style={{color: 'white'}}>{details.charges}</Box>
                                    </Box>
                                </Box>}
                                placement="top">
                                <Box display="flex" mt={1} alignItems="center" justifyContent="center"
                                     flexDirection="column"
                                     style={{cursor: 'pointer'}}>
                                    <Typography variant="h6" color="textPrimary"
                                                className={` ${classes.typography.fontBold}`}
                                                style={{lineHeight: 1}}>
                                        {exchangeRate.primary.code} {details.total}
                                    </Typography>
                                    {
                                        (!inactiveSecondCurrency && activedPaySecondCurrency) &&
                                        <Typography variant="body1" color="textSecondary"
                                                    className={` ${classes.typography.fontBold}`}>
                                            {exchangeRate.secondary.code} {totalSecondaryCurrency.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </Typography>
                                    }
                                </Box>
                            </BootstrapTooltip>
                        </Hidden>
                    </Grid>
                    <Grid item xs={1}/>
                </Grid>
            </Grid>
        </React.Fragment>

    )
};

export default withRouter(AmountDetail)