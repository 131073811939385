import React from "react";
import {connect, useDispatch} from "react-redux";
import {calculateAccommodation} from "../../selector";
import {setDiscount, setQuantity} from "../../actions/sync"
import Classes from '../../../../styles'
import AccommodationTable from '../../components/payments/AccommodationTable';
import DetailsConsumptionsTable from '../../components/payments/DetailsConsumptionsTable'

import {Box, Button, Typography} from '@material-ui/core/';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {Grid} from "@material-ui/core";
import {groupAccosByRoomName} from "../../utils/accomodationsGroup";

import {makeStyles} from "@material-ui/core/styles";
import Tooltip from '@material-ui/core/Tooltip';
import CancelAccount from "../../../../assets/icons/money.svg";
import ModalView from "../../../common/components/modals/ModalView"
import {openModalView} from "../../../common/actions/utilsActions"

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        fontSize: '1em',
        fontWeight: "410"

    },
}));

function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const Accommodation = ({
                           accommodation,activedPaySecondCurrency, groupedAccomodations, accoDiscount, taxes, services, total,
                           subTotal, taxDiscount,display, setDiscount, setQuantity
                       }) => {
    const existingAccos = Object.keys(accommodation.items).length === 0;
    const classes = Classes();
    const dispatch = useDispatch();
    console.log(activedPaySecondCurrency)
    const columns = {
        "Item": {
            "name": "Item",
            "colSpan": "1",
            "align": "center"
        },
        "Price": {
            "name": "Price",
            "colSpan": "1",
            "align": "center"
        },
        "Quantity": {
            "name": "Quantity",
            "colSpan": "1",
            "align": "center"
        },
        "Amount": {
            "name": "Amount",
            "colSpan": "1",
            "align": "right"
        },
    };

    if (!display) {
        return (
            <p>No Accommodations</p>
        )
    }
    return (
        <Grid container direction="row" spacing={2} justify="space-between" alignItems="center">
            <Grid item xs={12}>
                <Box display="flex">
                    <Box flexGrow={1}>
                        <Typography variant="h6" color="textSecondary" className={classes.typography.pageSubTitles}>
                            Accomodations
                        </Typography>
                    </Box>
                    <Box>
                        {!existingAccos ?
                            <BootstrapTooltip title={"View details"} placement="top">
                                <Button variant="contained" size="small" color="secondary"
                                        className={`${classes.tables.btnView} ${classes.styles.shadow1}`}
                                        onClick={() => dispatch(openModalView("accomodation"))}>
                                    <VisibilityIcon/>
                                </Button>
                            </BootstrapTooltip> :
                            <Button variant="contained" size="small" color="secondary"
                                    className={`${classes.tables.btnView} ${classes.styles.shadow1} ${classes.buttons.disabledButton}`}
                                    disabled={existingAccos}>
                                <VisibilityIcon/>
                            </Button>
                        }
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                {
                    !existingAccos ?
                        <AccommodationTable
                            columns={columns}
                            data={groupedAccomodations}
                            total={total}
                            subTotal={subTotal}
                            taxes={taxes}
                            services={services}
                            discount={accoDiscount}
                            selectableRows={false}
                            setDiscount={setDiscount}
                            setQuantity={setQuantity}
                            enableDiscount={existingAccos}
                        /> :
                        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column"
                             css={{height: 295, padding: 10, border: "1px solid #e0e0e0"}}>
                            <img src={CancelAccount}
                                 style={{marginBottom: '1em', width: 70, height: 70, opacity: 0.4}}/>
                            <Typography variant="subtitle1" style={{color: '#9e9e9e'}}>
                                There are no accomodations to pay
                            </Typography>
                        </Box>
                }

            </Grid>
            <ModalView
                title={"Detail Accommodation by outlet"}
                keyValue={"accomodation"}
                body={<DetailsConsumptionsTable
                    data={accommodation.items}
                    setQuantity={setQuantity}
                    allowQuantityChange={true}
                    checkboxAllowed={true}
                    allowDelete={false}
                />}
            />
        </Grid>
    )
};

const mapStateToProps = (state) => {
    const accommodations = state.selectedConsumption.accommodation;
    const activedPaySecondCurrency = state.paymentTypeSelected.activedPaySecondCurrency
    const groupedAccos = groupAccosByRoomName(accommodations.items);
    const accomodationsTotalValues = calculateAccommodation(state);
    const taxDiscount = state.client.applyDiscount
    return {
        accommodation: accommodations,
        groupedAccomodations: groupedAccos,
        activedPaySecondCurrency:activedPaySecondCurrency,
        total: accomodationsTotalValues["total"],
        subTotal: accomodationsTotalValues["subTotal"],
        taxes: accomodationsTotalValues["taxes"],
        services: accomodationsTotalValues["services"],
        accoDiscount: accommodations.discount,
        taxDiscount:taxDiscount,
        display: accommodations.items
    }
};
export default connect(mapStateToProps, {setDiscount, setQuantity})(Accommodation)