import React, {useEffect, useState} from 'react';
import {validateNumericalInput} from "../../utils/formatConsumptions";
import {connect} from "react-redux";
// CSS
import Classes from '../../../../styles'
// MATERIAL UI
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@material-ui/core/';


const AccomodationTable = (props) => {
    const classes = Classes();
    const rows = props.data;
    const columnsLenght = Object.keys(props.columns).length;
    const countryCode = localStorage.getItem('country')
    const taxValue = localStorage.getItem('taxValue')
    const baseDetails = {
        "total":props.total,
        "subTotal": props.subTotal,
        "services": props.services,
        "taxes": props.taxes,
        "discount": props.discount
    }
    const [details, setDetails] = useState(baseDetails)

    useEffect(() => {
        if(countryCode==='CL'){
            if(props.paymentSecondaryCurrency){
                if(!props.taxDiscount){
                    const newTaxes = baseDetails.subTotal * taxValue
                    const newTotal = baseDetails.subTotal + newTaxes + baseDetails.services - baseDetails.discount
                    setDetails({
                        "total":newTotal,
                        "subTotal": props.subTotal,
                        "services": props.services,
                        "taxes": newTaxes,
                        "discount": props.discount
                    })
                }else{
                    const newTotal = baseDetails.subTotal + baseDetails.services - baseDetails.discount
                    setDetails({
                        "total":newTotal,
                        "subTotal": props.subTotal,
                        "services": props.services,
                        "taxes": 0,
                        "discount": props.discount
                    })
                }
            }else{
                const newTaxes = baseDetails.subTotal * taxValue
                const newTotal = baseDetails.subTotal + newTaxes + baseDetails.services - baseDetails.discount
                setDetails({
                    "total":newTotal,
                    "subTotal": props.subTotal,
                    "services": props.services,
                    "taxes": newTaxes,
                    "discount": props.discount
                })
            }

        }else{
            setDetails(baseDetails)
        }
    }, [props.paymentSecondaryCurrency,props.discount]);

    const validateAccoDiscount = (event, subTotal, tax, service) => {
        const total = subTotal + tax + service;
        let value = Number(event.target.value);
        if (value >= total) {
            value = total
        }
        const discount = validateNumericalInput(value);
        props.setDiscount(discount);
        const detailsNewDiscount = {
            "total":details.total,
            "subTotal": details.subTotal,
            "services": details.services,
            "taxes": details.taxes,
            "discount": discount
        }
        setDetails(detailsNewDiscount)
    };

    return (
        <Paper elevation={0} className={`${classes.styles.shadow1}`} style={{minHeight: 270}}>
            <TableContainer component={Paper} className={`${classes.styles.shadow1}`} style={{maxHeight: 350}}>
                <Table stickyHeader aria-label="sticky table" size="small"
                       className={`${classes.tables.table} white-table`}>
                    <TableHead>
                        <TableRow>
                            {Object.values(props.columns).map((column, index) => (
                                <TableCell
                                    key={index + 1}
                                    align={column.align}
                                    colSpan={column.colSpan}
                                    className={classes.tables.tableCell}
                                >
                                    {column.name}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody style={{overflowY: "auto", maxHeight: "213px"}}>
                        {Object.values(rows).map((stay, index) => (
                                <TableRow key={index + 1}>
                                    <TableCell className={classes.tables.tableCell}>{stay.description}</TableCell>
                                    <TableCell
                                        className={`${classes.tables.tableCell} rightSide`}>{stay.price.total.toFixed(2)}</TableCell>
                                    <TableCell
                                        className={`${classes.tables.tableCell} rightSide ${classes.tables.rowInput}`}>{stay.hasOwnProperty('maxQuantity') ? stay.maxQuantity : stay.quantity}</TableCell>
                                    <TableCell
                                        className={`${classes.tables.tableCell} rightSide`}>{(stay.quantity * stay.price.total).toFixed(2)}</TableCell>
                                </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer>
                <Table aria-label="spanning table" size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell
                                colSpan={columnsLenght - 1}
                                align="right"
                                className={`${classes.tables.td} 
                                         ${classes.tables.tableCell}
                                         ${classes.colors.black}
                                         ${classes.typography.fontBold}`}>
                                Subtotal
                            </TableCell>
                            <TableCell align="right"
                                       className={`${classes.tables.td} ${classes.tables.tableCell}`}>
                                {details.subTotal.toFixed(2)}
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.tables.rowHighlight}>
                            <TableCell colSpan={columnsLenght - 1} align="right"
                                       className={`${classes.tables.td} ${classes.tables.tableCell} ${classes.colors.black} ${classes.typography.fontBold}`}>
                                Taxes
                            </TableCell>
                            <TableCell align="right"
                                       className={`${classes.tables.td} ${classes.tables.tableCell}`}>{details.taxes.toFixed(2)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={columnsLenght - 1} align="right"
                                       className={`${classes.tables.td} ${classes.tables.tableCell} ${classes.colors.black} ${classes.typography.fontBold}`}>
                                Charges
                            </TableCell>
                            <TableCell align="right"
                                       className={`${classes.tables.td} ${classes.tables.tableCell}`}>{details.services.toFixed(2)}</TableCell>
                        </TableRow>
                        {props.clientType === 'GUEST' &&
                            <TableRow className={classes.tables.rowHighlight}>
                                <TableCell colSpan={columnsLenght - 1}
                                           align="right"
                                           className={`${classes.tables.td}
                                       ${classes.tables.tableCell} 
                                       ${classes.colors.black} 
                                       ${classes.typography.fontBold}`}>
                                    Discount
                                </TableCell>
                                <TableCell align="right" className={`${classes.tables.td} ${classes.tables.tableCell}`}>
                                    <TextField style={{width: '6em'}} type="number" name="discount" id="accoDiscount"
                                               value={props.discount}
                                               inputProps={{min: 0, step: 1}}
                                               className={` ${classes.typography.fontBold}`}
                                               onChange={event => validateAccoDiscount(event, details.subTotal, details.taxes, details.services)}
                                    />
                                </TableCell>
                            </TableRow>
                        }
                        <TableRow>
                            <TableCell colSpan={columnsLenght - 1} align="right"
                                       className={` ${classes.tables.tableCell} ${classes.colors.black} ${classes.typography.fontBold}`}>
                                Total
                            </TableCell>
                            <TableCell align="right"><h3>{details.total.toFixed(2)}</h3></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

const mapStateToProps = (state) => {
    return {
        clientType: state.client.customerType,
        paymentSecondaryCurrency: state.paymentTypeSelected.activedPaySecondCurrency,
        taxDiscount: state.client.applyDiscount
    }
};
export default connect(mapStateToProps,)(AccomodationTable)